












import Vue, { PropType } from 'vue';
import MAutocompleteUsersPure from './AutocompleteUsersPure.vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
/*

ToDo: Add spacing. 16px between lines and from left/right

 */

export default Vue.extend({
  name: 'm-autocomplete-users',
  components: { MAutocompleteUsersPure },
  props: {
    value: { type: Object as PropType<UserEntity>, default: () => ({}) },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      users: (state: UsersState) => state.stream.users,
    }),
    selected: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('onUserChanged', value);
      },
    },
  },
  // mounted() {
  //   this.items = this.users;
  // },
  methods: {
    fullName(user: UserEntity) {
      return getFullName(user);
    },
    async onSearchChanged(/*value*/) {
      // this.loading = true;
      // try {
      //   ToDo: Use real search here after we get rid of itemsForSource
      //   this.items = await this.$store.dispatch(new SearchUsers(value));
      // } catch (e) {
      //   console.error('Error while do search', e);
      // } finally {
      //   this.loading = false;
      // }
    },
  },
});

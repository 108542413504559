



































import Vue from 'vue';
import AppBar from '@/components/AppBar.vue';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';
import { RootState } from '@/store/types';
import NotificationDialog from '@/components/NotificationDialog.vue';
import NotificationSnackbar from '@/components/NotificationSnackbar.vue';
import GuidanceSnackbar from '@/components/GuidanceSnackbar.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import { UsersState } from '@/store/users';
import WelcomeDialog from '@/components/WelcomeDialog.vue';
import RateMentor from '@/components/RateMentor.vue';
import CommentDialog from '@/components/Inputs/CommentDialog.vue';
import Loader from '@/components/Loader.vue';
import MMatchingDialog from '@/components/Matching/MatchingDialog';

export default Vue.extend({
  name: 'App',
  components: {
    CommentDialog,
    WelcomeDialog,
    BottomNavigation,
    NotificationSnackbar,
    NotificationDialog,
    AppBar,
    GuidanceSnackbar,
    RateMentor,
    Loader,
    MMatchingDialog,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
      wsConnected: ({ events: state }) => state.isConnected,
      isRegistered: ({ events: state }) => state.isRegistered,
      forceLogout: (state: RootState) => state.forceLogout,
    }),
  },
  watch: {
    tenant(tenant) {
      const theme = this.$vuetify.theme.themes.light;
      const colors = tenant.config.theme.colors;
      theme.primary = colors.primary;
      theme.secondary = colors.secondary;
      theme.accent = colors.accent;
      const title = tenant.attributes.title;
      document.title = title ? title : 'OTP Community';
      if (tenant.config.theme.favicon) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = tenant.config.theme.favicon;
      }
      if (this.wsConnected && this.me) {
        this.wsRegister();
      }
    },
    me() {
      if (this.wsConnected && this.tenant) {
        this.wsRegister();
      }
    },
    wsConnected(wsConnected) {
      if (wsConnected && this.me && this.tenant) {
        this.wsRegister();
      }
    },
    async forceLogout(state) {
      if (state) {
        await this.$keycloak.keycloak.logout();
      }
    },
  },
  onIdle() {
    console.log('enter idle mode');
    if (this.isRegistered) {
      this.$socket.client.emit('idle', { state: true });
    } else console.log('Still not registered');
  },
  onActive() {
    console.log('exit idle mode');
    if (this.isRegistered) {
      this.$socket.client.emit('idle', { state: false });
    } else console.log('Still not registered');
  },
  methods: {
    wsRegister() {
      this.$socket.client.emit('register', { userId: this.me.id, tenantId: this.tenant.id });
    },
  },
});

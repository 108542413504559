


































































































import Vue, { PropType } from 'vue';
import { MentoringType, UserEntity } from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';
import { TasksState } from '@/store/tasks';
import { TransferUsers, UsersState } from '@/store/users';

//import ButtonWithConfirmation from '@/components/ButtonWithConfirmation.vue';

export default Vue.extend({
  name: 'ModalTransferMentors',
  props: {
    user: Object as PropType<UserEntity>,
    data: Object as PropType<UserEntity>,
  },
  components: {
    //ButtonWithConfirmation,
  },
  data() {
    return {
      transfers: {},
      isTransferAvailable: false,
    };
  },
  computed: {
    ...mapState<TasksState>('tasks', {
      mentees: (state: TasksState) => state.mentees,
    }),
    ...mapState<UsersState>('users', {
      users: (state: UsersState) =>
        state.me.attributes.mentoringType === MentoringType.Mentor
          ? [state.me, ...state.stream.users]
          : state.stream.users,
    }),
    ...mapGetters('users', {
      getUserById: 'getUserById',
      getUserMentees: 'getUserMentees',
    }),

    mentors() {
      return this.users.filter((user) => user.attributes.mentoringType === MentoringType.Mentor);
    },
    isMentor() {
      return this.data?.attributes.mentoringType === MentoringType.Mentor;
    },
    isMentee() {
      return this.data?.attributes.mentoringType === MentoringType.Mentee;
    },
    formatTransferText() {
      return this.$t('modalTransfer.transferText', {
        name: this.getFullName(this.data),
        isMentor: this.isMentor ? 'Mentees' : '',
      });
    },
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    getUserMentor(user: UserEntity) {
      return this.getUserById(user.mentorId);
    },

    getMentees(user: UserEntity) {
      return this.getUserMentees(user);
    },
    addTransfer(mentee: UserEntity, mentor: UserEntity) {
      this.transfers[mentee.id] = mentor;
      this.isTransferAvailable = this.checkIsTransferAvailable();
    },
    checkIsTransferAvailable() {
      for (const menteeId in this.transfers) {
        const menteeRecord = this.isMentor
          ? this.getMentees(this.data).find((mentee) => mentee.id === +menteeId)
          : this.data;

        if (menteeRecord?.mentorId !== this.transfers[menteeId].id) {
          return true;
        }
      }

      return false;
    },
    async doTransfer() {
      const actualTransfers = [];
      for (const menteeId in this.transfers) {
        const menteeRecord = this.isMentor
          ? this.getMentees(this.data).find((mentee) => mentee.id === +menteeId)
          : this.data;
        const mentorId = this.transfers[menteeId].id;
        if (menteeRecord.mentorId !== mentorId) {
          actualTransfers.push({ menteeId: +menteeId, mentorId });
        }
      }
      if (actualTransfers.length > 0) {
        if (await this.$store.dispatch(new TransferUsers(actualTransfers))) {
          this.$emit('closeModal');
        }
      }
    },
    clear() {
      this.transfers = {};
      this.isTransferAvailable = false;
    },
  },
});
























import Vue from 'vue';

export default Vue.extend({
  name: 'Dialog',
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {},
  methods: {
    closeDialog() {
      this.$emit('closeModal', true);
    },
  },
  watch: {
    isModalOpen(newVal) {
      this.isOpen = newVal === true;
    },
  },
});

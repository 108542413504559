












































import Vue, { PropType } from 'vue';
import { Slot, Slots, Weekdays } from '@mentessa/types';
import { formatFullDate, formatOneHourSlot, getSlotNextDate } from '@/utils/weekdays';

export default Vue.extend({
  name: 'm-slot-picker',
  props: {
    userSlots: { type: Object as PropType<Slots>, default: () => ({}) },
    loading: { type: Boolean, default: false },
    timeSlot: { type: Object as PropType<Slot> },
    weekday: { type: String as PropType<Weekdays> },
  },
  computed: {
    weekdays() {
      const weekdays = Object.keys(this.userSlots) as Weekdays[];
      weekdays.sort((l, r) => getSlotNextDate(l, '12:00').getTime() - getSlotNextDate(r, '12:00').getTime());
      return weekdays;
    },
    availableSlots() {
      return this.userSlots[this.weekday] ?? [];
    },
    suggestedDate() {
      if (this.weekday && this.timeSlot) {
        const date = getSlotNextDate(this.weekday, this.timeSlot.time);
        return formatFullDate(date);
      } else return '';
    },
    isSlotSelected() {
      return (slot) => slot.time === this.timeSlot?.time;
    },
  },

  methods: {
    toggleWeekday(weekday: Weekdays) {
      this.$emit('onWeekdayChanged', this.weekday === weekday ? undefined : weekday);
      this.$emit('onTimeSlotChanged', undefined);
    },
    selectSlot(slot: Slot) {
      this.$emit('onTimeSlotChanged', slot);
    },
    slotsCount(weekday: Weekdays) {
      return this.userSlots[weekday]?.length ?? 0;
    },
    slotInterval(slot: Slot) {
      return formatOneHourSlot(slot.time);
    },
    async bookSlot() {
      this.$emit('onSlotBooked', this.weekday, { ...this.timeSlot, available: false });
    },
    reset() {
      this.$emit('onTimeSlotChanged', undefined);
      this.$emit('onWeekdayChanged', undefined);
    },
  },
});

















































































import Vue, { PropType } from 'vue';
import { MentoringType, UserEntity } from '@mentessa/types';
import { SetFavoriteStatus } from '@/store/users';

export default Vue.extend({
  name: 'UserCard',
  props: {
    user: Object as PropType<UserEntity>,
  },
  computed: {
    isMentor() {
      return this.user?.attributes.mentoringType === MentoringType.Mentor;
    },
  },
  methods: {
    openUserDetail(userId: number) {
      this.$emit('openUserDetails', userId);
    },
    async toggleFavorite(user) {
      await this.$store.dispatch(new SetFavoriteStatus(user, !user.isFavorite));
    },
  },
});

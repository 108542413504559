






















































































































import FlipCard from '@/components/FlipCard.vue';
import { MessageEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import { LoadNextStreamPage } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import UserDetail from '@/components/UserDetail.vue';
import Loader from '@/components/Loader.vue';
import { CreateRequest, LoadNextRequestsPage, MessagesState } from '@/store/messages';
import ImagePicker from '@/components/Inputs/ImagePicker.vue';

export default Vue.extend({
  name: 'RequestsStream',
  components: {
    ImagePicker,
    Loader,
    UserDetail,
    FlipCard,
  },
  props: {
    requests: { type: Array as PropType<MessageEntity[]> },
  },
  data: () => ({
    loadingTrigger: false,
    showUserDetails: false,
    detailCarouselIndex: 0,
    newRequest: {
      text: '',
      attrs: {
        url: '',
        description: '',
        image: '',
      },
    },
    isRequestValid: true,
    createRequestDialog: false,
    detailedUser: undefined,
  }),
  computed: {
    ...mapState<MessagesState>('messages', {
      isLoading: (state: MessagesState) => state.requests.isLoading,
    }),
    ...mapGetters('messages', {
      isNewPageAvailable: 'isNewRequestsPageAvailable',
    }),
  },
  watch: {
    createRequestDialog() {
      this.newRequest.text = '';
      this.newRequest.attrs.url = '';
      this.newRequest.attrs.description = '';
      this.newRequest.attrs.image = '';
    },
  },
  methods: {
    async checkContent() {
      this.loadingTrigger = !this.loadingTrigger;
      if (this.loadingTrigger) {
        setTimeout(async () => {
          await this.$store.dispatch(new LoadNextRequestsPage());
        }, 500);
      }
    },
    async detailCarouselChangeItem(index) {
      if (index === -1) {
        return;
      }
      const desiredId = this.users[index].id.toString();
      const currentId = this.$route.params.id;
      if (index === this.users.length - 1 && this.isNewPageAvailable) {
        await this.$store.dispatch(new LoadNextStreamPage());
      }
      if (desiredId !== currentId) {
        await this.$router.replace({ name: 'RequestDetails', params: { id: desiredId.toString() } });
      }
    },
    openUserDetails(request: MessageEntity) {
      this.detailedUser = request.user;
      this.showUserDetails = true;
    },
    closeDetails() {
      this.showUserDetails = false;
    },
    async createRequest() {
      await this.$store.dispatch(new CreateRequest(this.newRequest));
      this.createRequestDialog = false;
    },
  },
  created() {
    if (this.$route.name === 'RequestDetails') {
      this.showUserDetails = true;
    }
  },
});

import { RouteConfig } from 'vue-router';
import Community from '../views/Community.vue';
import Profile from '../views/Profile.vue';
import Chat from '../views/Chat.vue';
import CommunityRules from '@/views/CommunityRules.vue';
import Welcome from '@/views/Welcome.vue';
import Mentoring from '@/views/Mentoring.vue';
import Dashboard from '@/views/Dashboard.vue';
import MentoringDetails from '@/views/MentoringDetails.vue';
import Badges from '@/views/Badges.vue';
import Admin from '@/views/Admin.vue';
import Requests from '@/views/Requests.vue';
import DashboardNew from '../views/DashboardNew.vue';
import UserMainNavigation from '../views/UserMainNavigation.vue';
import LearnAbout from '../views/LearnAbout.vue';
import Invite from '../views/Invite.vue';
import MatchingQuestionnaire from '../views/MatchingQuestionnaire.vue';
import { toBoolean } from '@/utils/convert';
import MatchingDashboard from '@/views/MatchingDashboard.vue';

function parseNumber(value: string | string[] | undefined) {
  try {
    const v = typeof value === 'string' ? value : value[0];
    return value != null ? Number(v) : undefined;
  } catch (e) {
    return undefined;
  }
}

export const routes: Array<RouteConfig> = [
  {
    path: '/community',
    name: 'Community',
    component: Community,
    props: (route) => ({
      matchingMode: toBoolean(route.query.matching),
    }),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/inbox',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/inbox/:roomId',
    name: 'ChatWithUser',
    component: Chat,
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: Community,
    props: (route) => ({
      matchingMode: toBoolean(route.query.matching),
    }),
  },
  {
    path: '/rules',
    name: 'CommunityRules',
    component: CommunityRules,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/mentoring',
    name: 'Mentoring',
    component: Mentoring,
    props: (route) => ({
      select: route.query.select,
      comments: route.query.comments,
      evaluate: route.query.evaluate,
      evaluateUserId: parseNumber(route.query.evaluateUserId),
      evaluateTaskSetId: parseNumber(route.query.evaluateTaskSetId),
    }),
  },
  {
    path: '/requests',
    name: 'Requests',
    component: Requests,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: (route) => ({
      mentorId: parseNumber(route.query.mentor),
      menteeId: parseNumber(route.query.mentee),
      managerId: parseNumber(route.query.manager),
      details: route.query.details,
      month: route.query.month,
    }),
  },
  {
    path: '/mentoringdetails',
    name: 'MentoringDetails',
    component: MentoringDetails,
  },
  {
    path: '/badges',
    name: 'Badges',
    component: Badges,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '*',
    redirect: '/hello',
  },
  {
    path: '/dashboard-new',
    name: 'Dashboard',
    component: DashboardNew,
  },
  {
    path: '/hello',
    name: 'UserMainNavigation',
    component: UserMainNavigation,
  },
  {
    path: '/learn-about',
    name: 'LearnAbout',
    component: LearnAbout,
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite,
  },
  {
    path: '/matching-questionnaire',
    name: 'MatchingQuestionnaire',
    component: MatchingQuestionnaire,
  },
  {
    path: '/matching-admin',
    name: 'Matching Dashboard',
    component: MatchingDashboard,
  },
];

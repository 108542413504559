import { ActionTree } from 'vuex';
import { RootMutations, RootState } from '@/store/types';
import { EventsActions, EventsMutations, EventsState } from './types';
import { ActivityEntity, EventType, EventVisibility, MentoringType, TaskSetStage, TaskState } from '@mentessa/types';
import { LoadMentees, LoadMentor, LoadTasks, TasksMutations } from '@/store/tasks';
import { GuidanceType, ShowGuidance } from '@/store/ui';
import { UsersMutations } from '@/store/users';

interface EventPayload {
  activity: ActivityEntity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const actions: ActionTree<EventsState, RootState> = {
  async [EventsActions.msgToClient](_, payload) {
    console.log('msgToClient ACTION', payload);
  },
  async [EventsActions.registerResult]({ commit }, { success }) {
    commit(EventsMutations.SetRegisterStatus, success);
  },
  async [EventsActions.anotherSession]() {
    console.log('You user was connected from other place');
  },
  async [EventsActions.logout]({ commit }, { reason }) {
    console.log('Force logout.', reason);
    commit(RootMutations.ForceLogout, { root: true });
  },
  async [EventsActions.activity]({ commit, rootState, dispatch }, payload: EventPayload) {
    // console.log('Activity:', payload);
    const activity = payload.activity;
    const event = payload.activity.event;
    const me = rootState.users.me;
    const m = (mutation: TasksMutations) => `tasks/${mutation}`;
    const um = (mutation: UsersMutations) => `users/${mutation}`;
    const oneOf = (events: EventType[]) => events.includes(event);

    if (
      activity.visibility === EventVisibility.Public ||
      (activity.visibility === EventVisibility.HR && me.attributes.isHR)
    ) {
      commit(m(TasksMutations.AppendEvent), activity, { root: true });
    }

    const isMentee = me.attributes.mentoringType === MentoringType.Mentee;
    const isSender = me.id === activity.sender.id;

    if (isMentee && [EventType.GoalsSent, EventType.TasksSent].includes(event)) {
      await dispatch(new LoadTasks(), { root: true });
      await dispatch(
        new ShowGuidance(event === EventType.GoalsSent ? GuidanceType.MenteeNewGoals : GuidanceType.MenteeNewTasks),
        { root: true },
      );
    } else if (!isSender && oneOf([EventType.TasksComplete, EventType.GoalsComplete])) {
      commit(m(TasksMutations.SetTaskDone), { task: { id: activity.attributes.entityId }, status: true });
      if (!isMentee) {
        await dispatch(new ShowGuidance(GuidanceType.MentorEvaluation, { user: activity.sender }), { root: true });
      }
    } else if (!isSender && oneOf([EventType.TasksNotDone, EventType.GoalsNotDone])) {
      commit(m(TasksMutations.SetTaskDone), { task: { id: activity.attributes.entityId }, status: false });
    } else if (!isSender && oneOf([EventType.TasksSubmitted, EventType.GoalsSubmitted])) {
      commit(m(TasksMutations.FindTaskSetAndSetStage), {
        id: activity.attributes.entityId,
        stage: TaskSetStage.Review,
      });
      await dispatch(new ShowGuidance(GuidanceType.MentorEvaluation), { root: true });
    } else if (oneOf([EventType.GoalsLastEvaluation, EventType.TasksLastEvaluation])) {
      commit(m(TasksMutations.FindTaskSetAndClear), activity.attributes.entityId);
    } else if (oneOf([EventType.SetProgress])) {
      commit(m(TasksMutations.FindTaskAndSetProgress), {
        id: activity.attributes.entityId,
        progress: activity.attributes.duration,
      });
    } else if (oneOf([EventType.BadgeReceived])) {
      const newBadge = {
        name: activity.attributes.title,
        received: new Date(),
        taskSetId: activity.attributes.entityId,
      };
      if (!isSender) commit(um(UsersMutations.AppendBadge), newBadge);
      await dispatch(new ShowGuidance(GuidanceType.NewBadge, { badgeName: newBadge.name }), { root: true });
    } else if (!isSender && oneOf([EventType.GoalChanged, EventType.TaskChanged])) {
      commit(m(TasksMutations.FindTaskAndSetText), {
        id: activity.attributes.entityId,
        text: activity.attributes.title,
      });
    } else if (!isSender && oneOf([EventType.GoalPaused, EventType.TaskPaused])) {
      commit(m(TasksMutations.FindTaskAndSetState), {
        id: activity.attributes.entityId,
        state: TaskState.Pause,
      });
    } else if (!isSender && oneOf([EventType.GoalResumed, EventType.TaskResumed])) {
      commit(m(TasksMutations.FindTaskAndSetState), {
        id: activity.attributes.entityId,
        state: TaskState.Active,
      });
    } else if (!isSender && oneOf([EventType.GoalDeleted, EventType.TaskDeleted])) {
      commit(m(TasksMutations.FindTaskAndDelete), {
        id: activity.attributes.entityId,
      });
    } else if (oneOf([EventType.MenteeTransfer])) {
      console.log('handle this', activity);
      if (me.attributes.mentoringType === MentoringType.Mentee) {
        await dispatch(new LoadMentor());
        await dispatch(new LoadTasks());
      } else if (me.attributes.mentoringType === MentoringType.Mentor) {
        await dispatch(new LoadMentees());
        await dispatch(new LoadTasks());
      }
    } else {
      if (!isSender) console.log(`Unhandled activity with ${EventType[event]}`, activity);
    }
  },
};

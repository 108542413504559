









































































































































import { SetFavoriteStatus } from '@/store/users';
import { SendDirectMessage } from '@/store/messages';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import MSlotPicker from '@/components/TimeSlots/SlotPicker.vue';
import { BookSession } from '@/store/matching';

extend('required', {
  ...required,
  message: ' ',
});

extend('max', {
  ...max,
  message: 'May not be greater than {length} characters',
});

export default Vue.extend({
  name: 'UserDetail',
  components: {
    ValidationProvider,
    ValidationObserver,
    MSlotPicker,
  },
  props: {
    user: { type: Object as PropType<UserEntity> },
  },
  data: () => ({
    message: '',
    isLoading: false,
    selectedSlot: undefined,
    selectedWeekday: undefined,
  }),
  computed: {
    matchesNumber() {
      return 0;
    },
    haveSlots() {
      const slots = this.user?.attributes.timeSlots?.slots;
      return slots && Object.keys(slots).length > 0;
    },
    ...mapGetters('tenant', {
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    isTimeSlotsEnabled() {
      return true;
      // ToDo this is only for demo
      //return this.isFeatureEnabled('timeSlots');
    },
    isSendBtnEnabled() {
      if (this.selectedWeekday) return this.selectedSlot != null;
      else return this.message?.length > 0;
    },
  },
  methods: {
    isFavorite() {
      return this.user.isFavorite;
    },
    async toggleFavorite() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },
    async sendMessage() {
      const success = await this.$store.dispatch(new SendDirectMessage(this.user.id, this.message));
      if (success) {
        this.message = '';
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSent')));
      } else {
        console.error('Cant send message');
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSendError')));
      }
    },
    async sendAppointment() {
      this.isLoading = true;
      try {
        const success = await this.$store.dispatch(
          new BookSession(this.user, this.selectedWeekday, this.selectedSlot, this.message),
        );
        if (success) {
          if (success) this.message = '';
          this.$refs.slotPicker.reset();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async send() {
      if (this.selectedSlot) await this.sendAppointment();
      else await this.sendMessage();
    },
  },
});

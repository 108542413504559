import Vue from 'vue';
import './logger';
import App from './App.vue';
import router from '@/router';
import store from './store';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import sentry from './plugins/sentry';
import { keycloakOptions as kcConfig, sentryOptions } from '@/config';
import { api } from '@/store/api';
import { Initialize } from '@/store/types';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import IdleVue from 'idle-vue';
import { AxiosRequestConfig } from 'axios';
Vue.config.productionTip = false;

Vue.use(sentry, sentryOptions);

const eventsHub = new Vue();
Vue.use(IdleVue, { eventEmitter: eventsHub, idleTime: 1000 * 60 * 10, store });

function tokenInterceptor() {
  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

function unauthorizedInterceptor(keycloak) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        console.error(`Unauthorized request.`);
        return keycloak.logout();
      } else if (error.response.status === 403) {
        console.error(`You have no access to ${error.response.config.url}`);
      }
      return Promise.reject(error);
    },
  );
}

type WithMeta<T> = T & {
  meta?: {
    startTime?: Date;
    endTime?: Date;
    duration?: number;
  };
};

api.interceptors.request.use(
  function (config: WithMeta<AxiosRequestConfig>) {
    config.meta = { startTime: new Date() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    const config = response.config as WithMeta<AxiosRequestConfig>;
    config.meta.endTime = new Date();
    config.meta.duration = config.meta.endTime.getTime() - config.meta.startTime.getTime();
    if (config.meta.duration > 1000) {
      console.log('[API]', response.request.responseURL, config.meta.duration);
    }
    return response;
  },
  function (error) {
    const config = error.config as WithMeta<AxiosRequestConfig>;
    if (config) {
      config.meta.endTime = new Date();
      config.meta.duration = config.meta.endTime.getTime() - config.meta.startTime.getTime();
      return Promise.reject(error);
    }
  },
);

function createApp() {
  const socket = io('/', {
    path: '/api/socket.io',
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
  });
  Vue.use(VueSocketIOExt, socket, { store });

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    async created() {
      Vue.prototype.$log.debug('App created');
      await this.$store.dispatch(new Initialize());
    },
  }).$mount('#app');
}

api
  .get('exists')
  .then(() => {
    Vue.use(VueKeyCloak, {
      onReady: (keycloak) => {
        // console.log('Keycloak authenticated:', keycloak.authenticated);
        tokenInterceptor();
        unauthorizedInterceptor(keycloak);
        createApp();
      },
      init: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      config: kcConfig,
    });
  })
  .catch(() => {
    window.location.href = 'https://mentessa.com';
  });

import { ActionTree } from 'vuex';
import { TenantActions, TenantMutations, TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { TenantEntity } from '@mentessa/types';
import i18n from '@/plugins/i18n';
import { MatchingMutations } from '@/store/matching';

export const actions: ActionTree<TenantState, RootState> = {
  async [TenantActions.Load]({ commit }) {
    const result = await api.get('tenant');
    const tenant: TenantEntity = result?.data;
    commit(TenantMutations.SetTenant, tenant);
    if (tenant?.config?.locale) {
      i18n.locale = ['sl', 'se', 'sr'].includes(tenant.config.locale) ? 'sl' : 'en';
    }
    if (tenant?.config.features.matching?.enabled) {
      commit(`matching/${MatchingMutations.SetMatchingEnabled}`, true, { root: true });
    }
    console.log(`Tenant "${tenant.attributes.name}" loaded`);
  },

  async [TenantActions.LoadLocations]({ commit }) {
    const result = await api.get('tenant/locations');
    const locations = result?.data;
    commit(TenantMutations.SetLocations, locations);
    console.log(`Locations loaded`);
  },

  async [TenantActions.LoadUsernames]({ commit }) {
    const result = await api.get('tenant/usernames');
    const usernames = result?.data;
    commit(TenantMutations.SetUsernames, usernames);
    console.log(`Usernames loaded`);
  },

  async [TenantActions.LoadKeycloakClient]() {
    try {
      const result = await api.get('tenants/kc-client');
      return result?.data;
    } catch (e) {
      console.log(e);
    }
  },

  async [TenantActions.UpdateKeycloakClient](_, { content }) {
    try {
      const result = await api.put('tenants/kc-client', content);
      return result?.data;
    } catch (e) {
      console.log(e);
    }
  },
};

















import Vue from 'vue';
import DashboardMatchingsTable from '@/components/Matching/DashboardMatchingsTable.vue';
import { mapState } from 'vuex';
import { UserEntity } from '@mentessa/types';
import { LoadAllMatchings, MatchingState } from '@/store/matching';

export default Vue.extend({
  name: 'm-matching-dashboard',
  components: { DashboardMatchingsTable },
  data: () => ({
    userFilter: null,
    searchFilterValues: {
      location: '',
      division: '',
      department: '',
      role: '',
      skills: '',
    },
  }),
  computed: {
    ...mapState<MatchingState>('matching', {
      isLoading: (state: MatchingState) => state.all.isLoading,
      matchings: (state: MatchingState) => state.all.matchings,
    }),
    usersFiltered() {
      const searchValues = this.searchFilterValues;
      let result = this.users;
      if (searchValues?.skills) {
        result = result.filter(
          (user) =>
            user.expertiseTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()) ||
            user.interestTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()),
        );
      }
      if (searchValues?.name) {
        const getFullName = (user) => `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
        result = result.filter((user) => getFullName(user).includes(searchValues.name));
      }
      return result;
    },
  },
  methods: {
    setSearchFilters(data) {
      this.searchFilterValues = data;
    },
    clearSearchFilters() {
      this.searchFilterValues = undefined;
    },

    userToRow(user: UserEntity) {
      const row = [user.identity.attributes.firstName, user.identity.attributes.lastName];
      return row.join(',');
    },
  },
  async mounted() {
    await this.$store.dispatch(new LoadAllMatchings());
  },
});

































































import Title_H2 from '@/components/Typography/Title_H2.vue';
//import Button from '@/components/Buttons/Button.vue';
import BoxSample from '@/components/DashboardComponents/BoxSample.vue';
import BoxChart from '@/components/DashboardComponents/BoxChart.vue';
import BoxListSample from '@/components/DashboardComponents/BoxListSample.vue';
import BoxList from '@/components/DashboardComponents/BoxList.vue';
import Vue from 'vue';
import Packery from 'packery';
import Draggabilly from 'draggabilly';

var pckry;

export default Vue.extend({
  name: 'Dashboard',
  components: {
    Title_H2,
    //Button,
    BoxSample,
    BoxChart,
    BoxListSample,
    BoxList,
  },
  data: () => ({
    isChartMaxi: false,
    filter: 'week',
    dataBoxSamples: [
      {
        title: 'Members',
        value: 195,
        id: 'dashboard1',
      },
      {
        title: 'Available Mentors',
        value: 57,
        id: 'dashboard2',
      },
      {
        title: 'Total Appointments',
        value: 15,
        id: 'dashboard3',
      },
      {
        title: 'Total Skills',
        value: 56,
        id: 'dashboard4',
      },
    ],
    dataBoxListSamples: [
      {
        title: 'Location',
        values: [
          { id: '23423', terms: 'Berlin', describes: '63%' },
          { id: 'se21', terms: 'Munich', describes: '22%' },
          { id: 'asdasd', terms: 'Frankfurt', describes: '15%' },
        ],
        id: 'dashboard6',
      },
      {
        title: 'Language',
        values: [
          { id: '23423', terms: 'English', describes: '63%' },
          { id: 'se21', terms: 'German', describes: '22%' },
          { id: 'asdasd', terms: 'French', describes: '15%' },
        ],
        id: 'dashboard7',
      },
      {
        title: 'Orientation',
        values: [
          { id: '23423', terms: 'Male', describes: '42%' },
          { id: '234231', terms: 'Female', describes: '40%' },
          { id: 'se21', terms: 'LGBTIQ+', describes: '8%' },
          { id: 'noind', terms: 'No Indication', describes: '10%' },
        ],
        id: 'dashboard8',
      },
      {
        title: 'Gender',
        values: [
          { id: '23423', terms: 'Male', describes: '63%' },
          { id: 'se21', terms: 'Female', describes: '22%' },
          { id: 'asdasd', terms: 'Diverse', describes: '15%' },
        ],
        id: 'dashboard9',
      },
      {
        title: 'Ethnicity',
        values: [
          { id: '23423', terms: 'White/Caucasian', describes: '63%' },
          { id: 'se21', terms: 'Latina/Latino', describes: '22%' },
          { id: 'asdasd', terms: 'African American', describes: '15%' },
          { id: 'asdasd2', terms: 'Asian', describes: '15%' },
        ],
        id: 'dashboard10',
      },
      {
        title: 'Country of origin',
        values: [
          { id: '23423', terms: 'USA', describes: '63%' },
          { id: 'se21', terms: 'Germany', describes: '22%' },
          { id: 'asdasd', terms: 'UK', describes: '15%' },
        ],
        id: 'dashboard11',
      },
    ],
    dataBoxList: [
      {
        title: 'Most Active Users',
        values: [
          {
            id: '123123',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
          {
            id: '1231cas',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
          {
            id: 'vf123',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
        ],
        id: 'dashboard12',
      },
      {
        title: 'Most Active Mentors',
        values: [
          {
            id: 'basb',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
          {
            id: 'vasda',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
          {
            id: 'asda',
            name: 'John Doe',
            location: 'Berlin',
            matches: '32',
            imgSrc: 'https://cdn.vuetifyjs.com/images/john.jpg',
          },
        ],
        id: 'dashboard13',
      },
    ],
  }),
  computed: {
    // ...mapState<MatchingState>('matching', {
    //   isLoading: (state: MatchingState) => state.all.isLoading,
    //   matchings: (state: MatchingState) => state.all.matchings,
    // }),
    // usersFiltered() {
    //   const searchValues = this.searchFilterValues;
    //   let result = this.users;
    //   if (searchValues?.skills) {
    //     result = result.filter(
    //       (user) =>
    //         user.expertiseTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()) ||
    //         user.interestTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()),
    //     );
    //   }
    //   if (searchValues?.name) {
    //     const getFullName = (user) => `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    //     result = result.filter((user) => getFullName(user).includes(searchValues.name));
    //   }
    //   return result;
    // },
  },
  methods: {
    // setSearchFilters(data) {
    //   this.searchFilterValues = data;
    // },
    // clearSearchFilters() {
    //   this.searchFilterValues = undefined;
    // },
    // userToRow(user: UserEntity) {
    //   const row = [user.identity.attributes.firstName, user.identity.attributes.lastName];
    //   return row.join(',');
    // },

    setFilter(filter) {
      this.filter = filter;

      // load functionality
    },
    handleMaximize() {
      this.isChartMaxi = !this.isChartMaxi;
      const chartDiv = this.$refs.chartDiv;

      if (this.isChartMaxi) {
        //console.log(pckry);
        pckry.fit(chartDiv, 0, chartDiv.offsetTop);
        // pckry.shiftLayout();
      } else {
        // pckry.shiftLayout();
      }

      setTimeout(() => {
        pckry.layout();
      }, 100);
    },
  },
  async mounted() {
    //await this.$store.dispatch(new LoadAllMatchings());
    // vanilla JS
    var grid = document.querySelector('.grid');
    // initialize with element
    pckry = new Packery(grid, {
      // options...
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      gutter: '.gutter-sizer',
      percentPosition: true,
      horizontalOrder: true,
    });

    pckry.getItemElements().forEach(function (itemElem) {
      var draggie = new Draggabilly(itemElem);
      pckry.bindDraggabillyEvents(draggie);
    });
  },
});

import { FilterConfig, MentoringType, UserEntity } from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'users';

export interface UserLoadingPageMeta {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemCount?: number;
  itemsPerPage?: number;
}

export interface UsersLoadingPage {
  items: UserEntity[];
  meta: UserLoadingPageMeta;
}

export interface FilterWithValue extends FilterConfig {
  value: { id?: number; name: string }[];
}

export type Filters = { [filter: string]: FilterWithValue };

export interface FilterSources {
  location: string[];
  division: string[];
  department: string[];
  role: string[];
  skills: string[];
  name: string[];
}

export interface UserStorage {
  users: UserEntity[];
  pagination: UserLoadingPageMeta;
  filters: Filters;
  isLoading: boolean;
  onlyFavorites: boolean;
}

export interface UsersState {
  seed: number; // Use it to randomise result for entire session
  stream: UserStorage;
  search: UserStorage;
  hidden: UserStorage;
  me?: UserEntity;
  detailedUser?: UserEntity;
  permissions: {
    canInvite: boolean;
    superAdmin: boolean;
  };
  filterSources: FilterSources;
  managedUsersId: number[];
}

export enum UsersActions {
  LoadNextStreamPage = '[Users] Load Next Stream Page',
  LoadNextSearchPage = '[Users] Load Next Search Page',
  GetMe = '[Users] Get Me',
  GetUserDetails = '[Users] Load Details',
  UpdateUserField = '[Users] Update field',
  ApplyFilter = '[Users] Apply Filter',
  ClearStream = '[Users] Clear Stream',
  InviteUser = '[Users] Invite User',
  UploadImage = '[Users] Upload Image',
  SetField = '[Users] Set Field',
  Save = '[Users] Save',
  LoadPermissions = '[Users] Load Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  ShowOnlyFavorites = '[Users] Show Only Favorites',
  ForceSeed = '[Users] Force Seed',
  ReadRules = '[Users] Read Rules',
  LoadHiddenUsers = '[Users] Load hidden users',
  TransferUsers = '[Users] Transfer Users',
  UpdateUserRole = '[Users] Update User Role',
  MakeHR = '[Users] Make HR',
  MakeManager = '[Users] Make Manager',
  RevokeHR = '[Users] Revoke HR',
  RevokeManager = '[Users] Revoke Manager',
  LoadManagedUsers = '[Users] Load Managed Users',
  ChangeManagerForUser = '[Users] Change Manager For User',
  AddAdminRole = '[Users] Add Admin Role',
  RevokeAdminRole = '[Users] Revoke Admin Role',
  DeleteUser = '[Users] Delete user',
}

export enum UsersMutations {
  SetStreamLoadingFlag = '[Users] Set Stream Loading Flag',
  SetSearchLoadingFlag = '[Users] Set Search Loading Flag',
  AddStreamPage = '[Users] Add Stream Page',
  AddSearchPage = '[Users] Add Search Page',
  AddUsersToStream = '[Users] Add Users To Stream',
  SetMe = '[Users] Set me',
  SetDetailedUser = '[Users] Set Detailed User',
  SetUserField = '[Users] Set User Field',
  SetStreamFilters = '[Users] Set Stream Filters',
  ClearStream = '[Users] Clear Stream',
  SetPermissions = '[Users] Set Permissions',
  SetFavoriteStatus = '[Users] Set Favorite Status',
  SetOnlyFavorites = '[Users] Set Only Favorites',
  SetSeed = '[Users] Set Seed',
  SetReadRules = '[Users] Set Read Rules',
  AddHiddenUsers = '[Users] Add Hidden Users',
  AppendBadge = '[Users] Append Badge',
  SetManagedUsers = '[Users] Set Managed users',
  AssignTaskSets = '[Users] Assign Task Sets',
  SetHRStatus = '[Users] Set HR Status',
  ApplyTransfers = '[Users] Apply Transfers',
  RemoveUser = '[Users] Remove User',
  SetSlotAvailable = '[Users] Set Slot available',
}

export class LoadNextStreamPage implements Action {
  type = `${moduleName}/${UsersActions.LoadNextStreamPage}`;
}

export class LoadNextSearchPage implements Action {
  type = `${moduleName}/${UsersActions.LoadNextSearchPage}`;
}

export class GetMe implements Action {
  type = `${moduleName}/${UsersActions.GetMe}`;
}

export class GetUserDetails implements Action {
  type = `${moduleName}/${UsersActions.GetUserDetails}`;

  constructor(public userId: number) {
    this.userId = userId;
  }
}

export class UpdateUserField implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserField}`;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(public field: string, public value) {
    this.field = field;
    this.value = value;
  }
}

export class ApplyFilter implements Action {
  type = `${moduleName}/${UsersActions.ApplyFilter}`;

  constructor(public filters: Filters) {
    this.filters = filters;
  }
}

export class ClearStream implements Action {
  type = `${moduleName}/${UsersActions.ClearStream}`;
}

export class InviteUser implements Action {
  type = `${moduleName}/${UsersActions.InviteUser}`;

  constructor(public email: string) {
    this.email = email;
  }
}

export class UploadImage implements Action {
  type = `${moduleName}/${UsersActions.UploadImage}`;

  constructor(public image: File) {
    this.image = image;
  }
}

export class SetUserField implements Action {
  type = `${moduleName}/${UsersActions.SetField}`;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(public field: string, public value) {
    this.field = field;
    this.value = value;
  }
}

export class SaveUser implements Action {
  type = `${moduleName}/${UsersActions.Save}`;
}

export class LoadPermissions implements Action {
  type = `${moduleName}/${UsersActions.LoadPermissions}`;
}

export class SetFavoriteStatus implements Action {
  type = `${moduleName}/${UsersActions.SetFavoriteStatus}`;

  constructor(public target: UserEntity, public enabled: boolean) {
    this.target = target;
    this.enabled = enabled;
  }
}

export class ShowOnlyFavorites implements Action {
  type = `${moduleName}/${UsersActions.ShowOnlyFavorites}`;

  constructor(public enabled: boolean) {
    this.enabled = enabled;
  }
}

export class ForceSeed implements Action {
  type = `${moduleName}/${UsersActions.ForceSeed}`;

  constructor(public seed: number) {
    this.seed = seed;
  }
}

export class ReadRules implements Action {
  type = `${moduleName}/${UsersActions.ReadRules}`;
}

export class LoadHiddenUsers implements Action {
  type = `${moduleName}/${UsersActions.LoadHiddenUsers}`;
}

export class TransferUsers implements Action {
  type = `${moduleName}/${UsersActions.TransferUsers}`;

  constructor(public transfers: { menteeId: number; mentorId: number }[]) {
    this.transfers = transfers;
  }
}

export class UpdateUserRole implements Action {
  type = `${moduleName}/${UsersActions.UpdateUserRole}`;

  constructor(public user: UserEntity, public role: MentoringType) {
    this.role = role;
    this.user = user;
  }
}

export class MakeHR implements Action {
  type = `${moduleName}/${UsersActions.MakeHR}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class MakeManager implements Action {
  type = `${moduleName}/${UsersActions.MakeManager}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class RevokeHR implements Action {
  type = `${moduleName}/${UsersActions.RevokeHR}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class RevokeManager implements Action {
  type = `${moduleName}/${UsersActions.RevokeManager}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class LoadManagedUsers implements Action {
  type = `${moduleName}/${UsersActions.LoadManagedUsers}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class ChangeManagerForUser implements Action {
  type = `${moduleName}/${UsersActions.ChangeManagerForUser}`;

  constructor(public user: UserEntity, public manager: UserEntity) {
    this.user = user;
    this.manager = manager;
  }
}

export class AddAdminRole implements Action {
  type = `${moduleName}/${UsersActions.AddAdminRole}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class RevokeAdminRole implements Action {
  type = `${moduleName}/${UsersActions.RevokeAdminRole}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}

export class DeleteUser implements Action {
  type = `${moduleName}/${UsersActions.DeleteUser}`;

  constructor(public user: UserEntity) {
    this.user = user;
  }
}































import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import Vue from 'vue';
import TabMembers from '@/components/Tabs/TabMembers.vue';
import { InviteUser } from '@/store/users';

export default Vue.extend({
  components: {
    TabMembers,
  },
  data: () => ({
    isFormValid: true,
    inviteEmail: '',
    progressBar: false,
    isAdmin: false,
    isMentor: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      isLoading: (state: UsersState) => state.stream.isLoading,
      me: (state: UsersState) => state.me,
    }),
  },
  methods: {
    isEmailValid(email: string): boolean {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    clearEmail(): void {
      this.inviteEmail = '';
    },
    sendInvitation(): void {
      this.$store.dispatch(new InviteUser(this.inviteEmail));
    },
  },
});




















import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import Vue from 'vue';
//import LayoutDefault from '@/layouts/LayoutDefault.vue';
//import Title_H4 from '@/components/Typography/Title_H4.vue';
//import Paragraph from '@/components/Typography/Paragraph.vue';

export default Vue.extend({
  components: {
    //LayoutDefault,
    //Title_H4,
    //Paragraph,
  },
  data: () => ({
    links: [
      {
        text: 'Meet the team',
        location: '',
        imageSrc: require('@/assets/user-menu-1.png'),
      },
      {
        text: 'Find a mentor',
        location: '',
        imageSrc: require('@/assets/user-menu-2.png'),
      },
      {
        text: 'Set goals for development',
        location: '',
        imageSrc: require('@/assets/user-menu-3.png'),
      },
      {
        text: 'Learn about the culture',
        location: '',
        imageSrc: require('@/assets/user-menu-4.png'),
      },
      {
        text: 'Set up matching',
        location: '',
        imageSrc: require('@/assets/user-menu-5.png'),
      },
      {
        text: 'Add new skills',
        location: '',
        imageSrc: require('@/assets/user-menu-6.png'),
      },
    ],
  }),
  computed: {
    ...mapState<UsersState>('users', {
      isLoading: (state: UsersState) => state.stream.isLoading,
      me: (state: UsersState) => state.me,
    }),
  },
  methods: {},
});

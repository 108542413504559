




































import Dialog from '@/components/Dialog/Dialog.vue';
import Paragraph from '@/components/Typography/Paragraph.vue';
import Title_H4 from '@/components/Typography/Title_H4.vue';
import Button from '@/components/Buttons/Button.vue';
import InputField from '@/components/InputField/InputField.vue';

import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@/utils/user';

export default Vue.extend({
  name: 'modal-delete-members',
  components: { Dialog, Paragraph, Title_H4, Button, InputField },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    user: Object as PropType<UserEntity>,
    // id: {
    //   type: String,
    //   required: true,
    // },
    // name: {
    //   type: String,
    //   required: true,
    // },
    title: {
      type: String,
      required: true,
      default: 'Delete',
    },
    promp: {
      type: String,
      required: true,
      default: 'Are you sure?',
    },
  },
  data() {
    return {
      deleteString: '',
    };
  },
  computed: {
    fullName() {
      return getFullName(this.user);
    },
    isDeleteConfirmed() {
      return this.deleteString === 'DELETE';
    },
  },
  watch: {
    isModalOpen(value) {
      if (!value) this.deleteString = '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', true);
    },
    updateField(value) {
      this.deleteString = value;
    },
    handleDelete() {
      this.$emit('onDelete');
    },
  },
});

import { Slot, UserEntity, Weekdays } from '@mentessa/types';

export interface Action {
  readonly type: string;
}

export interface Matching {
  id: number;
  createdAt: string;
  initiator: UserEntity;
  matchingUsers: { id: number; user: UserEntity }[];
}

export const moduleName = 'matching';

export interface MatchingPair {
  left: UserEntity;
  right: UserEntity;
}

export interface Loadable {
  isLoading: boolean;
}

export interface MatchingDialogState extends Loadable {
  visible: boolean;
}

export interface MatchingsStore extends Loadable {
  matchings: Matching[];
}

export interface MatchingState {
  matchingDialog: MatchingDialogState;
  users: UserEntity[];
  isLoaded: boolean;
  enabled: boolean;
  isLoading: boolean;
  matchings: Matching[];
  all: MatchingsStore;
}

export enum MatchingActions {
  AddMatchings = '[Matching] Add Matchings',
  ShowMatchingDialog = '[Matching] Show Matching Dialog',
  CloseMatchingDialog = '[Matching] Close Matching Dialog',
  LoadMatchingUsers = '[Matching] Load matching Users',
  NotifyMatchedUsers = '[Matching] Notify matched Users',
  LoadAllMatchings = '[Matching] Load All Matchings',
  BookSession = '[Matching] Book session',
  RandomizeSlots = '[Matching] Randomize Slots',
  ClearBookedSlots = '[Matching] Clear Booked Slots',
}

export enum MatchingMutations {
  SetMatchingDialogVisible = '[Matching] Set Matching Dialog Visible',
  SetMatchingDialogLoading = '[Matching] Set Matching Dialog Loading',
  SetMatchingUsers = '[Matching] Set Matching Users',
  SetMatchingLoading = '[Matching] Set Matching Loading',
  SetMatchingEnabled = '[Matching] Set Matching Enabled',
  SetMatchings = '[Matching] Set Matchings',
  SetMatchingIsLoaded = '[Matching] Set Matching Is Loaded',
  SetAllMatchings = '[Matching] Set All Matchings',
  SetAllMatchingsIsLoading = '[Matching] Set All Matchings Is Loading',
}

export class AddMatchings implements Action {
  constructor(public pairs: MatchingPair[]) {
    this.pairs = pairs;
  }

  type = `${moduleName}/${MatchingActions.AddMatchings}`;
}

export class ShowMatchingDialog implements Action {
  type = `${moduleName}/${MatchingActions.ShowMatchingDialog}`;
}

export class CloseMatchingDialog implements Action {
  type = `${moduleName}/${MatchingActions.CloseMatchingDialog}`;
}

export class LoadMatchingUsers implements Action {
  type = `${moduleName}/${MatchingActions.LoadMatchingUsers}`;
}

export class NotifyMatchedUsers implements Action {
  constructor(public message: string) {
    this.message = message;
  }

  type = `${moduleName}/${MatchingActions.NotifyMatchedUsers}`;
}

export class LoadAllMatchings implements Action {
  type = `${moduleName}/${MatchingActions.LoadAllMatchings}`;
}

export class BookSession implements Action {
  constructor(public user: UserEntity, public weekday: Weekdays, public slot: Slot, public message: string) {
    this.user = user;
    this.weekday = weekday;
    this.slot = slot;
    this.message = message;
  }

  type = `${moduleName}/${MatchingActions.BookSession}`;
}

export class RandomizeSlots implements Action {
  type = `${moduleName}/${MatchingActions.RandomizeSlots}`;
}

export class ClearBookedSlots implements Action {
  type = `${moduleName}/${MatchingActions.ClearBookedSlots}`;
}

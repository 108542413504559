import { UsersLoadingPage, UsersMutations, UsersState } from './types';
import { MutationTree } from 'vuex';
import { BadgeInfo, MentoringType, TaskSetEntity, TaskSetStage, UserEntity } from '@mentessa/types';
import * as Sentry from '@sentry/vue';

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const mutations: MutationTree<UsersState> = {
  [UsersMutations.AddStreamPage](state: UsersState, data: UsersLoadingPage) {
    data.items.forEach((user) => {
      if (!user.identity.attributes.image) {
        user.identity.attributes.image = '/user-empty.png';
      }
      if (user.taskSets == null) {
        user.taskSets = [];
      }
    });
    // Warning, data.items will be changed.
    state.stream.users.push(...shuffle(data.items));
    Object.assign(state.stream.pagination, data.meta);
    // -------------------
    const users = state.stream.users;
    const locations = new Set<string>();
    const divisions = new Set<string>();
    const departments = new Set<string>();
    const skills = new Set<string>();
    const names = new Set<string>();
    users.forEach((user: UserEntity) => {
      if (user.attributes.location) locations.add(user.attributes.location.trim());
      if (user.attributes.division) divisions.add(user.attributes.division.trim());
      user.attributes.department?.split(' / ').forEach((department) => departments.add(department.trim()));
      user.expertiseTags.forEach((tag) => skills.add(tag.name));
      user.interestTags.forEach((tag) => skills.add(tag.name));
      names.add(`${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`);
    });

    state.filterSources = {
      location: Array.from(locations).sort(),
      division: Array.from(divisions).sort(),
      department: Array.from(departments).sort(),
      role: ['Mentor', 'Menti', 'HR', 'Menadžer'],
      skills: Array.from(skills).sort(),
      name: Array.from(names).sort(),
    };
  },

  [UsersMutations.AddSearchPage](state: UsersState, data: UsersLoadingPage) {
    state.search.users.push(...data.items);
    Object.assign(state.search.pagination, data.meta);
  },

  [UsersMutations.SetStreamLoadingFlag](state: UsersState, isLoading: boolean) {
    state.stream.isLoading = isLoading;
  },
  [UsersMutations.SetSearchLoadingFlag](state: UsersState, isLoading: boolean) {
    state.search.isLoading = isLoading;
  },
  [UsersMutations.SetMe](state: UsersState, user: UserEntity) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.me = user;
    Sentry.setUser({
      id: user.id.toString(),
      email: user.identity.attributes.email,
    });
  },
  [UsersMutations.SetDetailedUser](state: UsersState, user: UserEntity) {
    if (!user.identity.attributes.image) {
      user.identity.attributes.image = '/user-empty.png';
    }
    state.detailedUser = user;
  },
  [UsersMutations.SetUserField](state: UsersState, dst) {
    const path = dst.field.split('.');
    let userPart = state.me;
    for (let i = 0; i < path.length - 1; ++i) {
      if (!userPart[path[i]]) userPart[path[i]] = {};
      userPart = userPart[path[i]];
    }
    // console.log('Update userPart', userPart[path[path.length - 1]], 'with value', dst.value);
    userPart[path[path.length - 1]] = dst.value;
  },
  [UsersMutations.SetStreamFilters](state: UsersState, filter) {
    state.stream.filters = filter;
  },
  [UsersMutations.ClearStream](state: UsersState, force = false) {
    if (state.stream.isLoading && !force) {
      console.warn('Trying to clear stream while loading data. Skip');
      return;
    }
    state.stream.users.splice(0);
    state.stream.pagination.currentPage = 0;
    state.stream.pagination.itemCount = 0;
    state.stream.pagination.totalItems = 0;
    state.stream.pagination.totalPages = 0;
  },
  [UsersMutations.SetPermissions](state: UsersState, permissions) {
    state.permissions = permissions;
  },
  [UsersMutations.SetFavoriteStatus](state: UsersState, options: { target: UserEntity; status: boolean }) {
    options.target.isFavorite = options.status;
  },
  [UsersMutations.SetOnlyFavorites](state: UsersState, enabled: boolean) {
    state.stream.onlyFavorites = enabled;
  },
  [UsersMutations.SetSeed](state: UsersState, seed: number) {
    state.seed = seed;
  },
  [UsersMutations.SetReadRules](state: UsersState) {
    state.me.attributes.rulesRead = true;
  },
  [UsersMutations.AddHiddenUsers](state: UsersState, users: UserEntity[]) {
    state.hidden.users = users;
  },
  [UsersMutations.AppendBadge](state: UsersState, badge: BadgeInfo) {
    if (state.me.attributes.badges && Array.isArray(state.me.attributes.badges)) state.me.attributes.badges.push(badge);
    else state.me.attributes.badges = [badge];
  },
  [UsersMutations.SetManagedUsers](state: UsersState, ids: number[]) {
    state.managedUsersId = ids;
  },
  [UsersMutations.AssignTaskSets](state: UsersState, taskSets: TaskSetEntity[]) {
    state.stream.users.forEach((user) => {
      if (user.attributes.mentoringType === MentoringType.Mentee) {
        user.taskSets = taskSets.filter((taskSet) => taskSet.menteeId === user.id);
      } else if (user.attributes.mentoringType === MentoringType.Mentor) {
        user.taskSets = taskSets.filter((taskSet) => taskSet.mentorId === user.id);
      } else {
        user.taskSets = [];
      }
    });
  },
  [UsersMutations.SetHRStatus](state: UsersState, options: { target: UserEntity; status: boolean }) {
    options.target.attributes.isHR = options.status;
  },
  [UsersMutations.ApplyTransfers](state: UsersState, transfers: { menteeId: number; mentorId: number }[]) {
    for (const transfer of transfers) {
      const mentee = state.stream.users.find((user) => user.id === transfer.menteeId);
      const newMentor = state.stream.users.find((user) => user.id === transfer.mentorId);
      const oldMentor = state.stream.users.find((user) => user.id === mentee.mentorId);
      if (!mentee || !newMentor || !oldMentor) {
        // How is it possible? Use correct arguments please
        continue;
      }
      mentee.mentorId = newMentor.id;
      const affectedTaskSets = [];
      mentee.taskSets.forEach((ts) => {
        if (ts.stage !== TaskSetStage.Close && ts.mentorId === oldMentor.id) {
          ts.mentorId = newMentor.id;
          affectedTaskSets.push(ts);
        }
      });
      oldMentor.taskSets = oldMentor.taskSets.filter((ts) =>
        affectedTaskSets.every((affectedTS) => affectedTS.id !== ts.id),
      );
      newMentor.taskSets.push(...affectedTaskSets);
    }
  },
  [UsersMutations.RemoveUser](state: UsersState, user: UserEntity) {
    const streamUser = state.stream?.users?.findIndex((u) => u.id === user.id);
    if (streamUser !== -1) {
      state.stream.users.splice(streamUser, 1);
    }
  },
  [UsersMutations.SetSlotAvailable](state: UsersState, { user, weekday, slot, isAvailable }) {
    const foundUser = state.stream?.users?.find((u) => u.id === user.id);
    if (!foundUser) return;
    const foundSlot = foundUser.attributes.timeSlots?.slots[weekday]?.find((s) => s.time === slot.time);
    if (!foundSlot) return;
    foundSlot.available = isAvailable;
  },
};

<template>
  <div class="box-list-sample">
    <Paragraph size="large" weight="bold" class="mb-2">{{ data.title }}</Paragraph>

    <template v-for="row in data.values">
      <dl :key="row.id">
        <dt>{{ row.terms }}</dt>
        <dd>{{ row.describes }}</dd>
      </dl>
    </template>
  </div>
</template>

<script>
import Paragraph from '@/components/Typography/Paragraph';
export default {
  name: 'BoxListSample',
  components: { Paragraph },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box-list-sample {
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  text-align: left;

  dl {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
}
</style>

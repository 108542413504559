























//import RadarChart from '@/components/RadarChart.vue';
import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
// import Title_H4 from '@/components/Typography/Title_H4';
import Paragraph from '@/components/Typography/Paragraph.vue';

export default Vue.extend({
  name: 'BoxChart',
  components: {
    //Title_H4,
    Paragraph, //RadarChart
    VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: 'Expertise',
          data: [80, 50, 30, 40, 100],
        },
        {
          name: 'Interests',
          data: [20, 90, 40, 80, 20],
        },
        // {
        //   name: 'Series 3',
        //   data: [44, 76, 78, 13, 43, 10, 35],
        // },
      ],
      chartOptions: {
        chart: {
          //457
          height: 450,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        colors: ['#EBCED9', '#DEEAF7'],
        title: {
          text: '',
        },
        stroke: {
          width: 1,
          colors: ['orange', 'green'],
        },
        fill: {
          opacity: 0.3,
          //colors: ['#EBCED9', '#DEEAF7'],
        },
        markers: {
          size: 3,
          colors: ['orange', 'green'],
          //colors: ['#ff0000', '#DEEAF7'],
        },
        dataLabels: {
          style: {
            colors: ['#000000'],
            //colors: ['#ff0000', '#E91E63'],
          },
        },
        xaxis: {
          categories: ['Cognitive', 'Interpersonal', 'Self-leadership', 'Digital', 'Banking'],
          labels: {
            show: true,
            style: {
              opacity: 1,
              colors: ['#57534e', '#57534e', '#57534e', '#57534e', '#57534e', '#57534e', '#57534e'],
              //fontSize: '12px',
              //fontFamily: 'Arial'
            },
          },
        },
      },
    };
  },
  computed: {
    maximizeIcon() {
      return require('@/assets/open_in_full.svg');
    },
  },
  methods: {
    // addData: function addData() {
    //   this.dataset.push(this.dataentry);
    //   this.labels.push(this.datalabel);
    //   this.datalabel = '';
    //   this.dataentry = '';
    // },
    maximize() {
      this.$emit('maximize');
    },
  },
});

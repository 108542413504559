














import Vue from 'vue';
import RequestsStream from '@/components/RequestsStream.vue';
import { mapState } from 'vuex';
import { FilterMyRequests, LoadNextRequestsPage, MessagesState } from '@/store/messages';

export default Vue.extend({
  components: {
    RequestsStream,
  },
  data: () => ({
    showOnlyMyRequests: false,
  }),
  computed: {
    ...mapState<MessagesState>('messages', {
      requests: (state: MessagesState) => state.requests.items,
    }),
  },
  watch: {
    async showOnlyMyRequests() {
      await this.$store.dispatch(new FilterMyRequests(this.showOnlyMyRequests));
    },
  },
  async created() {
    await this.$store.dispatch(new LoadNextRequestsPage());
  },
});

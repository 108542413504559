var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.flipped ? 'flip-container flipped' : 'flip-container'},[_c('div',{staticClass:"flipper",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"front",staticStyle:{"background":"#deeaf8","display":"flex","flex-direction":"column","justify-content":"center","flex-grow":"1","position":"absolute"},on:{"click":function($event){$event.stopPropagation();!_vm.isEditMode ? (_vm.flipped = true) : ''}}},[_vm._t("front",[_c('div',{staticStyle:{"height":"100%","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","bottom":"0","z-index":"2","width":"100%"}},[(_vm.isEditMode)?_c('div',{staticClass:"ma-2 d-flex flex-row"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","small":"","width":"calc(50% - 4px)"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.cancelEditing($event)}}},[_vm._v(" "+_vm._s(_vm.$t('requests.cancel'))+" ")]),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary","width":"calc(50% - 4px)","disabled":!_vm.isRequestValid},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeRequest($event)}}},[_vm._v(" "+_vm._s(_vm.$t('requests.save'))+" ")])],1):_vm._e()]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","position":"absolute","top":"4px","right":"0","z-index":"2"}},[(_vm.isEditable)?_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 mr-1",staticStyle:{"z-index":"999"},attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2466765973)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isEditMode = true}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('requests.edit')))])],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.deleteRequest}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('requests.delete')))])],1)],1)],1):_vm._e()],1),_c('div',{staticStyle:{"width":"100%","height":"100%","background-color":"#f1f1f1","display":"flex","flex-direction":"column","justify-content":"space-between"},style:(_vm.isEditMode ? 'overflow: auto' : '')},[_c('v-form',{staticClass:"pa-2",model:{value:(_vm.isRequestValid),callback:function ($$v) {_vm.isRequestValid=$$v},expression:"isRequestValid"}},[(!_vm.isEditMode && _vm.editedRequest.text)?_c('p',{staticClass:"pt-3 pl-3 pr-8 mb-0 m-request-heading"},[_vm._v(" "+_vm._s(_vm.editedRequest.text)+" ")]):_vm._e(),(_vm.isEditMode)?_c('v-text-field',{staticClass:"mt-1 pr-8 m-request-heading",attrs:{"flat":"","solo":"","background-color":"rgba(0,0,0,.1)","label":_vm.$t('requests.title'),"dense":"","rules":[
                  function (v) { return (v && v.length > 0) || _vm.$t('validation.required'); },
                  function (v) { return (v || '').length <= 40 || _vm.$t('validation.max40'); } ]},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.editedRequest.text),callback:function ($$v) {_vm.$set(_vm.editedRequest, "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedRequest.text"}}):_vm._e(),(_vm.isEditMode)?_c('v-text-field',{staticClass:"my-1 m-request-url",attrs:{"flat":"","solo":"","auto-grow":true,"background-color":"rgba(0,0,0,.1)","label":_vm.$t('requests.link'),"dense":"","rules":[
                  function (v) { return (v || '').length <= 250 || _vm.$t('validation.max250'); },
                  function (v) { return !v ||
                    (v && (v.startsWith('http://') || v.startsWith('https://'))) ||
                    _vm.$t('validation.httpPrefixRequired'); } ]},model:{value:(_vm.editedRequest.attrs.url),callback:function ($$v) {_vm.$set(_vm.editedRequest.attrs, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedRequest.attrs.url"}}):_vm._e(),(_vm.editedRequest.attrs.url && !_vm.isEditMode)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 mt-1",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openLink($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("link")])],1)]}}],null,false,775858774)},[_c('span',[_vm._v(_vm._s(_vm.editedRequest.attrs.url))])]):_vm._e(),(_vm.isEditMode || _vm.editedRequest.attrs.description)?_c('v-textarea',{staticClass:"mb-1",style:(!_vm.isEditMode ? 'max-height: 94px; overflow: auto' : ''),attrs:{"flat":"","solo":"","auto-grow":true,"background-color":_vm.isEditMode ? 'rgba(0,0,0,.1)' : 'transparent',"readonly":!_vm.isEditMode,"label":_vm.$t('requests.description'),"rows":"1","hide-details":!_vm.isEditMode,"dense":"","rules":[function (v) { return (v || '').length <= 300 || _vm.$t('validation.max300'); }]},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.editedRequest.attrs.description),callback:function ($$v) {_vm.$set(_vm.editedRequest.attrs, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedRequest.attrs.description"}}):_vm._e()],1),_c('ImagePicker',{class:{ 'block-edit': !_vm.isEditMode, 'avatar-change-image': _vm.isEditMode },on:{"change":_vm.applyImage},model:{value:(_vm.editedRequest.attrs.image),callback:function ($$v) {_vm.$set(_vm.editedRequest.attrs, "image", $$v)},expression:"editedRequest.attrs.image"}})],1)])])],2),_c('div',{staticClass:"back",staticStyle:{"position":"relative","display":"flex","flex-direction":"column","flex-grow":"1","height":"100%","color":"white","min-height":"400px"},style:({
        background: 'url(request.user.image) no-repeat center',
        backgroundSize: 'cover',
      }),on:{"click":function($event){$event.stopPropagation();_vm.flipped = false}}},[_vm._t("back",[_c('UserCard',{attrs:{"user":_vm.request.user},on:{"openUserDetails":function($event){return _vm.$emit('openUserDetails', _vm.request)}}})])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }
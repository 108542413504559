











































































































































import Pagination from '@/components/Pagination/Pagination.vue';
import Tags from '@/components/Tags/Tags.vue';
import InputField from '@/components/InputField/InputField.vue';
import ModalDeleteConfirm from '@/components/Modals/ModalDeleteConfirm.vue';
import { mapState } from 'vuex';
import { AddAdminRole, DeleteUser, InviteUser, RevokeAdminRole, UsersState } from '@/store/users';
import Vue from 'vue';
import { UserEntity } from '@mentessa/types';
import { getFullName } from '@mentessa/frontend/src/utils/user';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'TabMembers',
  props: {},
  components: {
    Pagination,
    Tags,
    InputField,
    ModalDeleteConfirm,
  },
  data: () => ({
    searchMember: '',
    isModalOpen: false,
    isModalDeleteOpen: false,
    isToastVisible: false,
    visibleDropdownId: undefined,
    checkedItems: [],
    userToDelete: undefined,
    currentPage: 1,
  }),

  computed: {
    ...mapState<UsersState>('users', {
      users: (state: UsersState) => state.stream.users,
      isLoading: (state: UsersState) => state.stream.isLoading,
    }),
    totalPages() {
      return Math.ceil(this.users.length / 10);
    },
    usersPage() {
      return this.users.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
    },
    isLoading() {
      if (this.matchingMode) return this.isMatchingLoading;
      else return this.isStreamLoading;
    },
    userToDeleteName() {
      return this.userToDelete?.identity.attributes.firstName ?? '';
    },
  },
  methods: {
    updateField(obj) {
      this[`${obj.name}`] = obj.value;
    },
    async doSearch(filter: string) {
      this.searchMember = filter;
      await this.setPage(1);
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.isModalDeleteOpen = false;
    },
    openDeleteModal(user: UserEntity) {
      this.userToDelete = user;
      this.isModalDeleteOpen = true;
    },
    toggleDropdown(id) {
      if (id === this.visibleDropdownId) {
        this.visibleDropdownId = '';
      } else {
        this.visibleDropdownId = id;
      }
    },
    hideDropdown(item) {
      if (item.target?.offsetParent?.className && !item.target.offsetParent.className.includes('member-dropdown')) {
        this.visibleDropdownId = '';
      }
    },
    toggleAll(value) {
      this.checkedItems = [];
      if (value === true) {
        this.users.forEach((item) => {
          this.checkedItems.push(item.id);
        });
      } else {
        this.checkedItems = [];
      }
    },

    // here i will add logic from ex-modal-delete-user
    async handleDeleteUser() {
      if (await this.$store.dispatch(new DeleteUser(this.userToDelete))) {
        await this.$store.dispatch(
          new ShowNotificationSnackbarAction(i18n.t('notifications.userDeleteSuccess').toString()),
        );
        this.closeModal();
      }
    },
    closeToast() {
      this.isToastVisible = false;
    },

    async setPage(page: number) {
      this.currentPage = page;
    },
    async makeAdmin(user: UserEntity) {
      await this.$store.dispatch(new AddAdminRole(user));
    },
    async revokeAdmin(user: UserEntity) {
      await this.$store.dispatch(new RevokeAdminRole(user));
    },
    async resendInvite(user: UserEntity) {
      await this.$store.dispatch(new InviteUser(user.identity.attributes.email));
    },
    getFullName: getFullName,
  },
  mounted() {
    this.setPage(1);
    // this.loadNextPage();
    // this.page = this.paginationInfo.currentPage;
    // this.totalPages = this.paginationInfo.totalPages;
  },
});



























import { Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { Matching } from '@/store/matching';
import MUserPreview from '@/components/Matching/UserPreview/UserPreviewPure.vue';
import { parseAndFormatDate, parseAndGetTime } from '@/store/utils/date';
import { getFullName } from '@/utils/user';

function sortByUserNamePredicate(isDesc: boolean, getter: (any) => UserEntity) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (l: any, r: any) =>
    isDesc
      ? getFullName(getter(l)).localeCompare(getFullName(getter(r)))
      : getFullName(getter(r)).localeCompare(getFullName(getter(l)));
}

function sortByDatePredicate(isDesc: boolean, getter: (any) => string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (l: any, r: any) => {
    return isDesc
      ? parseAndGetTime(getter(l)) - parseAndGetTime(getter(r))
      : parseAndGetTime(getter(r)) - parseAndGetTime(getter(l));
  };
}

function extractMember(index: number) {
  return (matching: Matching): UserEntity => matching.matchingUsers?.[index]?.user;
}

function extractInitiator(matching: Matching): UserEntity {
  return matching.initiator;
}

function extractCreatedAt(matching: Matching): string {
  return matching.createdAt;
}

export default Vue.extend({
  components: { MUserPreview },
  props: {
    matchings: { type: Array as PropType<Matching[]>, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  name: 'DashboardMatchingsTable',
  data: () => ({
    headers: [
      { value: 'user1', width: 180, sortable: true },
      { value: 'user2', width: 180, sortable: true },
      { value: 'initiator', width: 180, sortable: true },
      { value: 'createdAt', width: 180, sortable: true },
    ],
  }),
  mounted() {
    this.headers.forEach((header) => (header.text = this.$t(`matchingDashboard.table.${header.value}`)));
  },
  methods: {
    customSort: function (items: Matching[], index, isDesc) {
      if (index.length === 0) return items;
      const key = index[0];
      const orderDesc = isDesc[0];
      switch (key) {
        case 'user1':
          items.sort(sortByUserNamePredicate(orderDesc, extractMember(0)));
          break;
        case 'user2':
          items.sort(sortByUserNamePredicate(orderDesc, extractMember(1)));
          break;
        case 'initiator':
          items.sort(sortByUserNamePredicate(orderDesc, extractInitiator));
          break;
        case 'createdAt':
          items.sort(sortByDatePredicate(orderDesc, extractCreatedAt));
          break;
      }

      return items;
    },
    parseAndFormatDate: parseAndFormatDate,
    extractMember: extractMember,
  },
});

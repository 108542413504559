



















import MentoringFeedRecord from '@/components/MentoringFeedRecord.vue';
import Vue, { PropType } from 'vue';
import { ActivityEntity, EventType } from '@mentessa/types';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'MentoringFeed',
  components: { MentoringFeedRecord },
  props: {
    events: { type: Array as PropType<ActivityEntity[]> },
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    filteredEvents() {
      return this.events.filter((r) => !(r.event === EventType.BadgeReceived && this.me.id === r.sender.id));
    },
  },
});

<template>
  <div class="box-sample">
    <Title_H4 weight="bold">{{ data.value }}</Title_H4>

    <Paragraph size="small" weight="medium">{{ data.title }}</Paragraph>
  </div>
</template>

<script>
import Title_H4 from '@/components/Typography/Title_H4';
import Paragraph from '@/components/Typography/Paragraph';
export default {
  name: 'BoxSample',
  components: { Title_H4, Paragraph },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box-sample {
  padding: 24px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  //min-height: 105px;
  text-align: left;
  width: 100%;

  @media (max-width: 767px) {
    padding: 16px;
  }
}
</style>

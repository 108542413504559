<template>
  <div class="box-list">
    <Paragraph size="large" weight="bold" class="mb-4">{{ data.title }}</Paragraph>

    <template v-for="row in data.values">
      <div :key="row.id" class="d-flex align-center justify-space-between pt-4 pb-4 list-item">
        <div class="d-flex align-center">
          <v-avatar class="mr-2">
            <img :src="row.imgSrc" alt="John" />
          </v-avatar>

          <div>
            <Paragraph size="small" weight="medium" class="mb-1">{{ row.name }}</Paragraph>
            <Paragraph size="small" weight="medium" class="mb-0" style="color: #a8a29e">{{ row.location }}</Paragraph>
          </div>
        </div>

        <div>
          <Paragraph size="small" weight="medium" class="mb-0">{{ row.matches }} matches</Paragraph>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Paragraph from '@/components/Typography/Paragraph.vue';
export default {
  name: 'BoxList',
  components: { Paragraph },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.box-list {
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  text-align: left;
  .list-item {
    border-top: 1px solid #dedede;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
}
</style>
